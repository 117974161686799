<template>
  <div>
    
    <b-overlay :show="loading">
      <div class="w-100 d-flex justify-content-between">
        <b-button variant="warning" @click="toBack">
          {{$t('message.back')}}
        </b-button>
      </div>

      <ValidationObserver ref="refFormObserver">
        <b-row>
          <b-col md="12">
            <b-card class="mt-2" v-if="!$route.params.id && !$route.params.ticketId">
              <!-- Client  -->
              <b-row>
                <b-col md="12">
                  <h4>
                    {{$t('message.client_text')}}
                  </h4>
                </b-col>

                <b-col md="4">
                  <ValidationProvider #default="validationContext" :name="$t('data.passport')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.passport')}}
                      </p>
                      <div class="wrapper-form">
                        <b-icon icon="book"></b-icon>
                        <input
                            v-model="clientModel.passport_serial"
                            maxlength="2"
                            class="form-control"
                            placeholder="AA"
                            style="width: 50px;"
                            type="text"
                            @input="passportSerialInput();changePassort()"
                        />
                        <input
                            class="form-control"
                            v-model="clientModel.passport_number"
                            ref="passportNumber"
                            placeholder="1234567"
                            v-mask="'#######'"
                            type="text"
                            @input="changePassort"
                        />
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col md="4">
                  <ValidationProvider #default="validationContext" :name="$t('data.given_date')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.given_date')}}
                      </p>
                      <div class="wrapper-form">
                        <!-- <b-icon icon="calendar2-check"></b-icon> -->
                        <input type="date" class="form-control" v-model="clientModel.given_date">
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col md="4">
                  <ValidationProvider #default="validationContext" :name="$t('data.expire_date')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.expire_date')}}
                      </p>
                      <div class="wrapper-form">
                        <input type="date" class="form-control" v-model="clientModel.expire_date" >
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col md="6">
                  <ValidationProvider #default="validationContext" :name="$t('data.firstNmae')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.firstNmae')}}
                      </p>
                      <div class="wrapper-form">
                        <b-icon icon="person"></b-icon>
                        <b-form-input
                            class="form-control"
                            v-model="clientModel.first_name"
                            @input="toUpperCaseFirstname"
                        />
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>
                <!-- Client lastName  -->
                <b-col md="6">
                  <ValidationProvider #default="validationContext" :name="$t('data.lastName')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.lastName')}}
                      </p>
                      <div class="wrapper-form">
                        <b-icon icon="person"></b-icon>
                        <input
                            class="form-control"
                            v-model="clientModel.last_name"
                            @input="toUpperCaseLastname"
                        />
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>
                <!-- Client birthday  -->
                <b-col md="4">
                  <ValidationProvider #default="validationContext" :name="$t('data.date_birthday')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.date_birthday')}}
                      </p>
                      <div class="wrapper-form">
                        <!-- <b-icon icon="calendar2-check"></b-icon> -->
                        <input type="date" class="form-control" v-model="clientModel.birthdate">
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>
                <!-- Client Passport Data  -->


                <b-col md="4">
                  <div class="wrapper">
                    <p class="wrapper-title">
                      {{$t('data.file')}}
                    </p>
                    <div class="wrapper-form">
                      <b-icon icon="file-earmark"></b-icon>
                      <input type="file" class="form-control" @change="fileChange">
                    </div>
                    <!-- <b-form-file v-model="visa.client.file" :state="Boolean(visa.client.file)"></b-form-file> -->
                  </div>
                </b-col>



                <!-- Client mobile number  -->
                <b-col md="4">
                  <ValidationProvider #default="validationContext"  :name="$t('data.mobile_number')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.mobile_number')}}
                      </p>
                      <div class="wrapper-form">
                        <b-icon icon="telephone"></b-icon>
                        <b-form-input v-model="number1" v-mask="'############'" type="number"></b-form-input>
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>
                <!-- Client mobile number additional -->
                <b-col md="4">
                  <div class="wrapper">
                    <p class="wrapper-title">
                      {{$t('data.mobile_number_additional')}}
                    </p>
                    <div class="wrapper-form">
                      <b-icon icon="telephone"></b-icon>
                      <b-form-input v-model="number2" type="number" v-mask="'############'"></b-form-input>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="mt-2">
              <b-row>
                <b-col md="12">
                  <h4>
                    {{$t('message.avia_sales_text')}}
                  </h4>
                </b-col>
                <b-col md="6">
                  <ValidationProvider #default="validationContext" :name="$t('data.date_departure')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.date_departure')}}
                      </p>
                      <div class="wrapper-form">
                        <!-- <b-icon icon="calendar2-check"></b-icon> -->
                        <input
                            type="date"
                            class="form-control"
                            v-model="dataModel.fly_datetime"
                        >
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col md="6">
                  <ValidationProvider #default="validationContext" :name="$t('data.date_leaving')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.date_leaving')}}
                      </p>
                      <div class="wrapper-form">
                        <!-- <b-icon icon="calendar2-check"></b-icon> -->
                        <input
                            type="date"
                            class="form-control"
                            v-model="dataModel.arrive_datetime"
                        >
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>


                <b-col md="6">
                  <ValidationProvider #default="validationContext" :name="$t('data.ticket_number')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.ticket_number')}}
                      </p>
                      <div class="wrapper-form">
                        <b-icon icon="aspect-ratio-fill"></b-icon>
                        <b-form-input v-model="dataModel.ticket_number"></b-form-input>
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col md="6">
                  <ValidationProvider #default="validationContext" :name="$t('data.booking_number')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.booking_number')}}
                      </p>
                      <div class="wrapper-form">
                        <b-icon icon="file-binary"></b-icon>
                        <b-form-input v-model="dataModel.order_number"></b-form-input>
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>
                <!-- Hotel actual_price  -->
                <b-col md="6">
                  <ValidationProvider #default="validationContext" :name="$t('data.ticket_price')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.ticket_price')}}
                      </p>
                      <div class="wrapper-form">
                        <b-icon icon="wallet2"></b-icon>
                        <b-form-input v-model="dataModel.market_price" type="number"></b-form-input>
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>
                <!-- Hotel market_price -->
                <b-col md="6">
                  <ValidationProvider #default="validationContext" :name="$t('data.tarif_price')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.tarif_price')}}
                      </p>
                      <div class="wrapper-form">
                        <b-icon icon="wallet2"></b-icon>
                        <b-form-input v-model="dataModel.actual_price" type="number"></b-form-input>
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col md="6">
                  <ValidationProvider #default="validationContext" :name="$t('data.payment_methods')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.payment_methods')}}
                      </p>
                      <div class="wrapper-form">
                        <b-icon icon="wallet2"></b-icon>
                        <v-select v-model="dataModel.currency_id" :options="currencies" label="name"></v-select>
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col md="6">
                  <ValidationProvider #default="validationContext" :name="$t('data.payment_type')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.payment_type')}}
                      </p>
                      <div class="wrapper-form">
                        <b-icon icon="wallet2"></b-icon>
                        <v-select v-model="dataModel.payment_type_id" :options="paymentType" label="name_ru"></v-select>
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col md="6">
                  <ValidationProvider #default="validationContext" :name="$t('message.AviaCompanies')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('message.AviaCompanies')}}
                      </p>
                      <div class="wrapper-form">
                        <b-icon icon="wallet2"></b-icon>
                        <v-select v-model="dataModel.avia_company_id" :options="aviaCompanyOptions" label="name"></v-select>
                      </div>
                      <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                    </div>
                  </ValidationProvider>
                </b-col>

                <!-- <b-col md="6">
                  <ValidationProvider #default="validationContext" :name="$t('data.reys_number')" rules="required">
                    <div class="wrapper">
                      <p class="wrapper-title">
                        {{$t('data.reys_number')}}
                      </p>
                      <div class="wrapper-form">
                        <b-icon icon="wallet2"></b-icon>
                        <b-form-input v-model="dataModel.reys_number" type="text"></b-form-input>
                      </div>
                      <span class="text-danger">
                        {{ validationContext.errors[0] }}
                      </span>
                    </div>
                  </ValidationProvider>
                </b-col> -->



                <b-col md="6">
                  <div class="wrapper">
                    <p class="wrapper-title">
                      {{$t('data.is_legal_collection')}}
                    </p>

                    <div class="wrapper-form border-0" style="padding: 7px !important">
                      <b-form-checkbox
                          class="px-2"
                          id="checkbox-1"
                          v-model="dataModel.is_legal_collection"
                          :value="true"
                          :unchecked-value="false"
                      ></b-form-checkbox>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col md="12">
            <b-card class="mt-2">
              <div class="d-flex">
                <p class="">
                  {{$t('data.whence_region')}}
                </p>
                /
                <p class="">
                  {{$t('data.where_region')}}
                </p>
              </div>
              <CountriesLine :timelineCountries="timelineCountries" @removeCountry="removeCountry" />
              <div class="w-100 mt-2">
                <v-select
                    :options="countries"
                    label="name_ru"
                    v-model="selectedCountry"
                    :pushTags="true"
                    taggable
                    @search="searchCountry"
                    @option:selected="selectedCountryOptions"
                    @search:blur="selectTempCountry"
                ></v-select>
              </div>
              <div class="w-100 d-flex justify-content-end mt-1">
                <b-button variant="primary" @click="addCountry" :disabled="selectedCountry === null">
                  <b-icon icon="plus"></b-icon>
                </b-button>
              </div>
              <span class="text-danger mt-2" v-if="timelineCountries.length === 0">
              {{$t('message.errrCountries')}}
            </span>
            </b-card>
          </b-col>
        </b-row>

      </ValidationObserver>
      <div class="w-100 my-3 d-flex justify-content-end">
        <b-button variant="primary" :disabled="disabledItem" @click="handleAdd">
          {{$t('message.Add')}}
        </b-button>
      </div>
    </b-overlay>
  </div>
</template>

<script>
  import {ValidationProvider, ValidationObserver} from 'vee-validate'
  import {addTickets, updateTickets, showTickets} from '@/api/avia-tickets/index';
  import {mapGetters, mapActions} from 'vuex'
  import {required, alphaNum} from '@validations';
  import {getCountries} from '@/api/countries/countries';
  import {getClients} from '@/api/clients/clients';
  import CountriesLine from '@/views/directories/clients/components/avia-tickets/CountriesLine';
  import moment from 'moment';
  import {getAviaCompanies} from '@/api/avia-companies/index'

  export default {
    name: "ticket-ticketAdd",
    components: {
      ValidationProvider,
      ValidationObserver,
      CountriesLine
    },
    data() {
      return {
        loading: false,
        required,
        alphaNum,
        number1:null,
        number2:null,
        disabledItem: false,
        file: null,
        clientModel: {
          id: null,
          first_name: '',
          last_name: '',
          birthdate: null,
          passport_serial: '',
          passport_number: '',
          given_date: null,
          expire_date: null,
          contacts: [{number: ''}, {number: ''}]
        },
        dataModel: {
          id: null,
          client_id: null,
          fly_datetime: null,
          arrive_datetime: null,
          ticket_number: null,
          order_number: null,
          actual_price: null,
          market_price: null,
          currency_id: null,
          payment_type_id: null,
          countries: [],
          is_legal_collection: true,
          // reys_number: null,
          avia_company_id: null,
        },
        ticket: {
          file: null,
          client: {
            id: null,
            first_name: '',
            last_name: '',
            birthdate: null,
            passport_serial: '',
            passport_number: '',
            contacts: [{
                number: ''
              },
              {
                number: ''
              }
            ],
            given_date: null,
            expire_date: null,
          },
          data: {
            id: null,
            client_id: null,
            fly_datetime: null,
            arrive_datetime: null,
            ticket_number: null,
            order_number: null,
            actual_price: null,
            market_price: null,
            currency_id: null,
            payment_type_id: null,
            countries: [],
            is_legal_collection: false,
            // reys_number: null,
            avia_company_id: null,
          },
        },
        clientsOptions: [],
        countries: [],
        selectedCountry: null,
        timelineCountries: [],
        timeout: null,
        countryTimeout: null,
        fisrtNameOptions: [],
        firstNameTimiout: null,
        lastNameOptions: [],
        lastNmaeTimeout: null,
        surNameOptions: [],
        surNameTimeout: null,
        tempFirstname: null,
        tempLastname: null,
        tempCountries: null,
        aviaCompanyOptions: [],
        dataTimeout: null,
      }
    },
    methods: {
      ...mapActions('settings', ['fetchCurrencies','fetchMethods']),
      toBack() {
        this.$router.go(-1)
      },
      handleAdd() {
        const isValid = this.$refs.refFormObserver.validate();
        if (isValid) {
          // disabled button
          this.disabledItem = true;
          this.loading = true;
          setTimeout( () => {
            this.disabledItem = false
          this.loading = false

          }, 1000)
          // client
          if(!this.$route.params.id) {
            this.ticket.client.id = this.clientModel.id;
            this.ticket.client.contacts = [];
            this.ticket.client.contacts.push({number:this.number1},{number:this.number2})
            this.ticket.client.contacts = JSON.stringify(this.ticket.client.contacts)
            this.ticket.client.first_name = this.clientModel.first_name
            this.ticket.client.last_name = this.clientModel.last_name
            this.ticket.client.birthdate = moment(this.clientModel.birthdate).format('YYYY-MM-DD')
            this.ticket.client.passport_serial = this.clientModel.passport_serial
            this.ticket.client.passport_number = this.clientModel.passport_number
            this.ticket.client.given_date = moment(this.clientModel.given_date).format('YYYY-MM-DD')
            this.ticket.client.expire_date = moment(this.clientModel.expire_date).format('YYYY-MM-DD')
          } else {
            this.ticket.client = {};
            this.ticket.client.id = this.$route.params.id
          }

          // ticket
          this.ticket.data.id = this.dataModel.id
          this.ticket.data.client_id = this.dataModel.client_id;
          this.ticket.data.fly_datetime = moment(this.dataModel.fly_datetime).format('YYYY-MM-DD HH:mm');
          this.ticket.data.arrive_datetime = moment(this.dataModel.arrive_datetime).format('YYYY-MM-DD HH:mm');
          this.ticket.data.ticket_number = this.dataModel.ticket_number;
          this.ticket.data.order_number = this.dataModel.order_number;
          this.ticket.data.actual_price = this.dataModel.actual_price;
          this.ticket.data.market_price = this.dataModel.market_price;
          this.ticket.data.currency_id = this.dataModel.currency_id.id; //select
          this.ticket.data.payment_type_id = this.dataModel.payment_type_id.id //select;
          this.ticket.data.avia_company_id = this.dataModel.avia_company_id.id
          // this.ticket.data.reys_number   = this.dataModel.reys_number;
          for (let i = 0; i < this.timelineCountries.length; i++) {
            if(!!this.timelineCountries[i].id) {
              this.ticket.data.countries.push(this.timelineCountries[i].id)
            } else {
              this.ticket.data.countries.push(this.timelineCountries[i].name_ru)
            }
          }
          this.ticket.data.is_legal_collection = this.dataModel.is_legal_collection;

          if(this.$route.params.ticketId) {
            updateTickets(this.ticket.data).then( () => {
              this.loading = false;
              this.toBack();
              this.disabledItem = false;
            })
          } else {
            let fd = new FormData();

            fd.append('client', JSON.stringify(this.ticket.client))
            fd.append('data', JSON.stringify(this.ticket.data))
            fd.append('passport_file', this.ticket.file)

            addTickets(fd).then(() => {
              this.loading = false
              this.toBack();
              this.disabledItem = false;
            })
          }
        }
      },
      addCountry() {
        if(typeof this.selectedCountry === 'string') {
          this.timelineCountries.push({
            name_ru: this.selectedCountry
          });
        }
        else {
          this.timelineCountries.push(this.selectedCountry);
        }
        this.selectedCountry = null;
      },
      removeCountry(index) {
        this.timelineCountries.splice(index, 1)
      },
      selectTempCountry(item) {
        if(this.tempCountries) {
          if(!this.selectedCountry) {
            this.selectedCountry = {name_ru:this.tempCountries};
          }
        }

      },
      searchCountry(item) {
        if (item.length > 0) {
          this.tempCountries = item
          clearTimeout(this.countryTimeout);
          this.countryTimeout = setTimeout(() => {
            getCountries({
              search: item
            }).then(res => {
              this.countries = res.data.data.data
            })
          }, 500)
        }
      },
      selectedCountryOptions() {
      },
      fetchShowData() {
        if(this.$route.params.ticketId) {
          showTickets(this.$route.params.ticketId).then( response =>{
            let res = response.data.data
            this.dataModel.id = res.id;
            this.dataModel.client_id = res.client_id;
            this.dataModel.fly_datetime = moment(res.fly_datetime).format('YYYY-MM-DD');
            this.dataModel.arrive_datetime = moment(res.arrive_datetime).format('YYYY-MM-DD');
            this.dataModel.ticket_number = parseInt(res.ticket_number);
            this.dataModel.order_number = parseInt(res.order_number);
            this.dataModel.actual_price = parseInt(res.actual_price);
            this.dataModel.market_price = parseInt(res.market_price);
            this.dataModel.currency_id = res.currency;
            this.dataModel.payment_type_id = res.payment_type
            this.timelineCountries = res.countries;
            this.dataModel.is_legal_collection = res.is_legal_collection;
            this.dataModel.avia_company_id = res.avia_company
          //  this.dataModel.reys_number = res.reys_number
          })
        }
      },
      fileChange(e) {
        this.file = e.target.files[0]
      },
      passportSerialInput() {
        this.clientModel.passport_serial = this.clientModel.passport_serial.toUpperCase();
        if(this.clientModel.passport_serial.length >= 2) {
          this.$refs.passportNumber.focus()
        }
      },
      changePassort() {
        if(this.clientModel.passport_serial.length > 0 && this.clientModel.passport_number.length > 0) {
          clearTimeout(this.dataTimeout);
          this.dataTimeout = setTimeout(() => {
            getClients({passport_serial: this.clientModel.passport_serial, passport_number: this.clientModel.passport_number}).then( response => {
              let res = response.data.data[0];
              this.clientModel.id = res.id;
              this.clientModel.first_name       = res.first_name;
              this.clientModel.last_name        = res.last_name;
              this.clientModel.birthdate        = res.birthdate;
              this.clientModel.passport_serial  = res.passport_serial;
              this.clientModel.passport_number  = res.passport_number;
              this.clientModel.given_date       = res.given_date;
              this.clientModel.expire_date      = res.expire_date;
              this.clientModel.contacts = JSON.parse(res.contacts);
              if (!this.clientModel.contacts[0]) {
                this.clientModel.contacts = new Array(this.clientModel.contacts)
              }
              if (this.clientModel.contacts.length === 1) {
                this.number1 = this.clientModel.contacts[0].number
              }
              if (this.clientModel.contacts.length === 2) {
                this.number1 = this.clientModel.contacts[0].number
                this.number2 = this.clientModel.contacts[1].number
              }
            })
          }, 400)
        }
      },
      toUpperCaseFirstname() {
        this.clientModel.first_name = this.clientModel.first_name.toUpperCase();
      },
      toUpperCaseLastname() {
        this.clientModel.last_name = this.clientModel.last_name.toUpperCase();
      },
    },
    created() {
      this.fetchCurrencies().then(res =>{
        this.dataModel.currency_id= res.data.data.filter(item => item.name === 'uzs')[0]
      });
      this.fetchMethods();
      getAviaCompanies().then( res => {
        this.aviaCompanyOptions = res.data.data
      });
      this.fetchShowData();
    },
    computed: {
      ...mapGetters('settings', ['currencies','paymentType'])
    },
  }
</script>
